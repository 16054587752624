import React, { lazy, Suspense, useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"

import usePageLoading from "components/utils/usePageLoading"
import useIFrameResizer from "components/utils/useIFrameResizer"

import { getCity, filterStore, locationStore, pageStore } from "state/store-zustand";

import SEO from "components/seo"
import { linksToBreadcrumbs } from "components/utils/schemaUtils"

import Footer from "components/footer"

import PostBody from "components/post/postBody"
import SyncData from "components/post/syncData"

import "../components/post/post.scss"

const PostTemplate = ({ data, pageContext }) => {

  const pageLoader = usePageLoading()
  const hasResizer = useIFrameResizer()

  const postData = data.allWpPost.edges[0].node
  const citySlug = postData?.postDetails?.city && postData.postDetails.city.length > 0
    ? postData.postDetails.city[0].slug
    : null

  const filters = filterStore((state) => state.filters)
  const setShouldSetURLParams = filterStore((state) => state.setShouldSetURLParams)

  const setCityCurrent = locationStore((state) => state.setCityCurrent)

  const embedded = pageStore((state) => state.embedded)
  const setPageHasMap = pageStore((state) => state.setPageHasMap)
  const setIsVibeGuide = pageStore((state) => state.setIsVibeGuide)
  const showFooter = pageStore((state) => state.showFooter)

  // TODO: set filters in template context
  // Filters are used for the map link

  // TODO: make this a util or reusable hook
  const { id } = pageContext
  const schema = postData
    ? {
      "@context": "https://schema.org",
      "@type": "Article",
      articleBody: postData.seo.opengraphuRL,
      dateModified: postData.seo.opengraphModifiedTime,
      datePublished: postData.seo.opengraphPublishedTime,
      url: postData.seo.opengraphuRL,
      author: postData.author.name
        ? [
          {
            "@context": "http://schema.org",
            "@type": "Person",
            name: postData.author.name,
          },
        ]
        : ``,
    }
    : {}
  const [links, setLinks] = useState([])
  const [schemaArticle, setSchemaArticle] = useState(schema)
  const breadcrumbItems = linksToBreadcrumbs(links)

  const postCategories = postData?.categories?.nodes
  const postCategory =
    postCategories && postCategories.length > 0
      ? postData?.categories?.nodes[0]?.name
      : null

  const firstCategory = postCategories[0]
  const typeName = firstCategory && firstCategory.name
  const isVibeGuide = typeName == "Vibe Guide"
  useEffect(() => {
    setIsVibeGuide(isVibeGuide)
  }, [isVibeGuide, setIsVibeGuide])

  // Set hasMap on first load
  useEffect (() => {
    setPageHasMap(true)
    setShouldSetURLParams(false)
  }, [])

  const [city, setCity] = useState(
    postData.postDetails.city && !isVibeGuide
      ? postData.postDetails.city[0]
      : null
  )

  useEffect(() => {
    const getFilterContext = async (filters) => {
      const cityPararm = filters.cities && filters.cities.length > 0
        ? filters.cities[0]
        : citySlug || null

      const cityFound = await getCity(cityPararm)

      if (cityFound) {
        // FIXME: Make this a util as child component
        setCity(cityFound)
        setCityCurrent(cityFound)
        //console.log('city from post ', city);
      }
    }

    if (!city) {
      getFilterContext(filters);
    }

  }, [filters, city]);

  const containerClass = hasResizer ? `container-resizable` : `container`


  return (
    <>
      <SyncData postCategory={postCategory} />
      <SEO
        lang="en-US"
        title={postData.seo.title}
        data={postData.seo}
        fallbackImage={postData?.featuredImage?.node?.mediaItemUrl}
        schemaData={[schemaArticle, breadcrumbItems]}
      />
      <div className={`post ${pageLoader} ${embedded ? "embedded" : ""}`}>
        <div className={`main ${containerClass}`}>

        <PostBody postData={postData} pageContext={pageContext} />
        {showFooter ?
          <Footer />
          : null}

        </div>
      </div>
    </>
  )
}

PostTemplate.whyDidYouRender = true
export default PostTemplate

export const postQuery = graphql`
  query PostQuery($id: Int!) {
    allWpPost(filter: { databaseId: { eq: $id } }) {
      edges {
        node {
          id
          excerpt
          title
          slug
          status
          date
          featuredImage {
            node {
              caption
              mediaItemUrl
            }
          }
          author {
            node {
              avatar {
                url
              }
              name
              description
              seo {
                social {
                  facebook
                  instagram
                  linkedIn
                  twitter
                }
              }
              profile_image {
                profileImage {
                  mediaItemUrl
                  id
                }
              }
              url
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
          seo {
            title
            opengraphAuthor
            focuskw
            canonical
            opengraphDescription
            metaDesc
            metaKeywords
            opengraphImage {
              mediaItemUrl
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphTitle
            opengraphType
            opengraphUrl
          }
          activityCategories {
            nodes {
              name
              slug
            }
          }
          tags {
            nodes {
              slug
              name
            }
          }
          vibes {
            nodes {
              databaseId
              slug
              name
            }
          }
          postDetails {
            bannerLayout
            bannerLink
            location {
              latitude
              longitude
              zoom
            }
            showAuthorDetails
            authorBiography
            authorName
            authorBio
            authorPhoto {
              mediaItemUrl
            }
            customAuthor
            showRelated
            vibeset {
              ... on WpVibeset {
                id
                title
                databaseId
                vibesetDetails {
                  exploreLink
                  gradientImage {
                    mediaItemUrl
                  }
                  gradientVideo {
                    mediaItemUrl
                  }
                }
              }
            }
            city {
              ... on WpCity {
                id
                title
                uri
                slug
                databaseId
                cityDetails {
                  icon {
                    databaseId
                    localFile {
                      publicURL
                    }
                  }
                  placemarker {
                    latitude
                    longitude
                    zoom
                  }
                  vibes {
                    slug
                    name
                  }
                }
              }
            }
          }
          blocks {
            originalContent
            dynamicContent
            name
            ... on WpAcfEmbedBlock {
              name
              embed {
                fieldGroupName
                heading
                embedCode
              }
            }
            ... on WpAcfGalleryBlock {
              galleryBlock {
                heading
                loop
                images {
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: DOMINANT_COLOR
                        )
                      }
                    }
                  }
                  caption
                }
              }
            }
            ... on WpAcfImageBlock {
              dynamicContent
              originalContent
              imageBlock {
                caption
                fieldGroupName
                style
                image {
                  id
                  localFile {
                    publicURL
                  }
                }
              }
            }
            ... on WpAcfMapBlock {
              dynamicContent
              originalContent
              name
              mapBlock {
                heading
                height
                location {
                  ... on WpCity {
                    id
                    cityDetails {
                      placemarker {
                        latitude
                        longitude
                      }
                    }
                  }
                  ... on WpNeighborhood {
                    id
                    neighborhood {
                      map {
                        latitude
                        longitude
                      }
                    }
                  }
                }
                map {
                  latitude
                  longitude
                }
                openInApp
                directions
                heatmap
                map3d
                mapTheme
                showMarkers
                markerStyle
                zoom
                width
              }
            }
            ... on WpAcfQuotesBlock {
              quoteCards {
                heading
                fieldGroupName
                quotes {
                  attribution
                  quote
                }
              }
            }
            ... on WpAcfSingCardBlock {
              singCard {
                affiliateLink
                eventId
                placeId
                orientation
                type
                title
                city
                note
                price
                subcategory
                description
                image
                overrideImage
                price
                showAppStoreBadges
                titleOverride
                vibes
                badge {
                  ... on WpBadge {
                    id
                    slug
                    badgeDetails {
                      description
                      icon {
                        mediaItemUrl
                      }
                    }
                  }
                }
                placeCardImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED
                        placeholder: DOMINANT_COLOR
                      )
                    }
                  }
                }
                post {
                  ... on WpPost {
                    id
                    excerpt
                    uri
                    content
                    title
                    postDetails {
                      city {
                        ... on WpCity {
                          title
                        }
                      }
                    }
                    categories {
                      nodes {
                        name
                      }
                    }
                    featuredImage {
                      node {
                        caption
                        mediaItemUrl
                      }
                    }
                  }
                }
              }
            }
            ... on WpAcfLinksBlock {
              originalContent
              linkCards {
                heading
                links {
                  link
                  image {
                    databaseId
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: DOMINANT_COLOR
                        )
                      }
                    }
                  }
                }
              }
            }
            ... on WpAcfSingCardsBlock {
              singCards {
                heading
                subheading
                style
                vibemapSuggests
                posts {
                  eventId
                  fieldGroupName
                  placeId
                  type
                  title
                  city
                  subcategory
                  description
                  note
                  image
                  vibes
                  categoryQuery
                  distanceQuery
                  editorialCategoryQuery
                  geoQuery {
                    latitude
                    longitude
                  }
                  numOfPlaces
                  searchQuery
                  activityQuery {
                    slug
                    name
                  }
                  vibeQuery {
                    name
                    slug
                  }
                  post {
                    ... on WpPost {
                      id
                      title
                      excerpt
                      uri
                      content
                      postDetails {
                        city {
                          ... on WpCity {
                            title
                          }
                        }
                      }
                      categories {
                        nodes {
                          name
                        }
                      }
                      featuredImage {
                        node {
                          caption
                          mediaItemUrl
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on WpAcfAuthorBlock {
              dynamicContent
              originalContent
              aboutAuthor {
                author {
                  name
                  nicename
                  nickname
                  description
                  seo {
                    social {
                      instagram
                      facebook
                      twitter
                    }
                  }
                  url
                  profile_image {
                    profileImage {
                      mediaItemUrl
                    }
                  }
                }
                blockName
                customFullName
                customProfileImage {
                  mediaItemUrl
                }
              }
            }
            ... on WpAcfVideoBlock {
              videoBlock {
                service
                style
                vimeoId
                youtubeId
                caption
              }
            }
          }
        }
      }
    }
  }
`
